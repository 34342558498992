import { subscribe } from "../fable-library-js.4.19.3/Observable.js";
import { value as value_1, some } from "../fable-library-js.4.19.3/Option.js";
import { Store$1__Update_Z1FC644CA, Store$1_$ctor_Z35EE7837 } from "./Types.fs.js";
import { defaultOf } from "../fable-library-js.4.19.3/Util.js";
import { Helpers_cmdHandler, Helpers_CmdHandler$2__Handle_321F9C1C, Helpers_CmdHandler$2__Dispose } from "./ElmishStore.fs.js";
import { empty } from "../fable-library-js.4.19.3/List.js";

export function subscribeImmediate(cb, obs) {
    let initValue = undefined;
    const disp = subscribe((v) => {
        if (initValue != null) {
            cb(v);
        }
        else {
            initValue = some(v);
        }
    }, obs);
    if (initValue != null) {
        return [value_1(initValue), disp];
    }
    else {
        throw new Error("Observable doesn\'t report value immediately upon subscription");
    }
}

export function makeWithCons(init, dispose, cons, props) {
    return cons(init(props), dispose)[0];
}

function makeCons(i, d) {
    const s = Store$1_$ctor_Z35EE7837(i, d);
    return [s, (f) => {
        Store$1__Update_Z1FC644CA(s, f);
    }];
}

export function make(init, dispose, props) {
    return makeWithCons(init, dispose, makeCons, props);
}

export function makeElmishWithCons(init, update, dispose, cons, props) {
    let _cmdHandler = defaultOf();
    const patternInput = init(props);
    const patternInput_1 = cons(patternInput[0], (m) => {
        Helpers_CmdHandler$2__Dispose(_cmdHandler);
        dispose(m);
    });
    const storeUpdate = patternInput_1[1];
    const dispatch = (msg) => {
        let _cmds = empty();
        storeUpdate((model) => {
            const patternInput_2 = update(msg, model);
            _cmds = patternInput_2[1];
            return patternInput_2[0];
        });
        Helpers_CmdHandler$2__Handle_321F9C1C(_cmdHandler, _cmds);
    };
    _cmdHandler = Helpers_cmdHandler(storeUpdate, dispatch);
    Helpers_CmdHandler$2__Handle_321F9C1C(_cmdHandler, patternInput[1]);
    return [patternInput_1[0], dispatch];
}

export function makeElmish(init, update, dispose, props) {
    return makeElmishWithCons(init, update, dispose, makeCons, props);
}

