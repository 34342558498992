import { singleton } from "../fable-library-js.4.19.3/AsyncBuilder.js";
import { class_type } from "../fable-library-js.4.19.3/Reflection.js";
import { disposeSafe, getEnumerator } from "../fable-library-js.4.19.3/Util.js";
import { Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF } from "./Core.fs.js";
import { cancel as cancel_1, createCancellationToken } from "../fable-library-js.4.19.3/Async.js";

export class AsyncDisposable {
    constructor(cancel) {
        this.cancel = cancel;
        this.isDisposed = 0;
    }
    DisposeAsync() {
        const this$ = this;
        return singleton.Delay(() => {
            if (this$.isDisposed === 0) {
                this$.isDisposed = 1;
                return singleton.Bind(this$.cancel(), () => singleton.Return(undefined));
            }
            else {
                return singleton.Zero();
            }
        });
    }
}

export function AsyncDisposable_$reflection() {
    return class_type("FSharp.Control.AsyncDisposable", undefined, AsyncDisposable);
}

function AsyncDisposable_$ctor_Z56C29B26(cancel) {
    return new AsyncDisposable(cancel);
}

export function AsyncDisposable_Create_Z56C29B26(cancel) {
    return AsyncDisposable_$ctor_Z56C29B26(cancel);
}

export function AsyncDisposable_get_Empty() {
    return AsyncDisposable_$ctor_Z56C29B26(() => singleton.Delay(() => singleton.Return(undefined)));
}

export function AsyncDisposable_Composite_2A996D8E(disposables) {
    return AsyncDisposable_$ctor_Z56C29B26(() => singleton.Delay(() => singleton.For(disposables, (_arg) => singleton.Bind(_arg.DisposeAsync(), () => singleton.Return(undefined)))));
}

export class Disposable {
    constructor(cancel) {
        this.cancel = cancel;
        this.isDisposed = 0;
    }
    Dispose() {
        const this$ = this;
        if (this$.isDisposed === 0) {
            this$.isDisposed = 1;
            this$.cancel();
        }
    }
}

export function Disposable_$reflection() {
    return class_type("FSharp.Control.Disposable", undefined, Disposable);
}

export function Disposable_$ctor_3A5B6456(cancel) {
    return new Disposable(cancel);
}

export function Disposable_Create_3A5B6456(cancel) {
    return Disposable_$ctor_3A5B6456(cancel);
}

export function Disposable_get_Empty() {
    return Disposable_$ctor_3A5B6456(() => {
    });
}

export function Disposable_Composite_Z20550E70(disposables) {
    return Disposable_$ctor_3A5B6456(() => {
        const enumerator = getEnumerator(disposables);
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                disposeSafe(enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]());
            }
        }
        finally {
            disposeSafe(enumerator);
        }
    });
}

export function FSharp_Control_IAsyncRxDisposable__IAsyncRxDisposable_ToDisposable(this$) {
    return {
        Dispose() {
            Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF(this$.DisposeAsync());
        },
    };
}

export function System_IDisposable__IDisposable_ToAsyncDisposable(this$) {
    return AsyncDisposable_Create_Z56C29B26(() => singleton.Delay(() => {
        disposeSafe(this$);
        return singleton.Zero();
    }));
}

export function AsyncDisposableModule_canceller() {
    const cts = createCancellationToken();
    return [AsyncDisposable_Create_Z56C29B26(() => singleton.Delay(() => {
        cancel_1(cts);
        return singleton.Zero();
    })), cts];
}

