import { initializeInfinite } from "../fable-library-js.4.19.3/Seq.js";
import { startImmediate } from "../fable-library-js.4.19.3/Async.js";
import { singleton } from "../fable-library-js.4.19.3/AsyncBuilder.js";

export const infinite = initializeInfinite((x) => x);

/**
 * Starts the asynchronous computation in the thread pool, or
 * immediately for Fable. Do not await its result. If no cancellation
 * token is provided then the default cancellation token is used.
 */
export function Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF(computation, cancellationToken) {
    startImmediate(computation, cancellationToken);
}

export const AsyncModule_empty = singleton.Delay(() => {
    return singleton.Zero();
});

export function AsyncModule_noop(_arg) {
    return AsyncModule_empty;
}

