import { class_type } from "../fable-library-js.4.19.3/Reflection.js";
import { render, nothing, css as css_1, svg, html } from "lit";
import { transform } from "./Template.fs.js";
import { join } from "../fable-library-js.4.19.3/String.js";
import { choose } from "../fable-library-js.4.19.3/Seq.js";
import { styleMap } from "lit/directives/style-map.js";
import { createObj } from "../fable-library-js.4.19.3/Util.js";
import { toSeq } from "../fable-library-js.4.19.3/Map.js";
import { repeat } from "lit/directives/repeat.js";
import { until } from "lit/directives/until.js";
import { defaultArg } from "../fable-library-js.4.19.3/Option.js";
import { guard } from "lit/directives/guard.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { ref } from "lit/directives/ref.js";

export class LitBindings {
    constructor() {
    }
}

export function LitBindings_$reflection() {
    return class_type("Lit.LitBindings", undefined, LitBindings);
}

export const LitHelpers_html = (() => {
    const tag = html;
    return (fmt) => transform(tag, fmt);
})();

export const LitHelpers_svg = (() => {
    const tag = svg;
    return (fmt) => transform(tag, fmt);
})();

export const LitHelpers_css = (() => {
    const tag = css_1;
    return (fmt) => transform(tag, fmt);
})();

/**
 * Just trims the braces {} out of a css block to be used in a `style` attribute
 */
export function LitHelpers_inline_css(css) {
    const matchValue = css.indexOf("{") | 0;
    if (matchValue === -1) {
        return css;
    }
    else {
        const i = matchValue | 0;
        const matchValue_1 = css.lastIndexOf("}") | 0;
        if (matchValue_1 > i) {
            return css.slice(i + 1, (matchValue_1 - 1) + 1);
        }
        else {
            return css;
        }
    }
}

export class Lit {
    constructor() {
    }
}

export function Lit_$reflection() {
    return class_type("Lit.Lit", undefined, Lit);
}

export function Lit_$ctor() {
    return new Lit();
}

/**
 * Interprets a template literal as an HTML template that can efficiently render to and update a container.
 */
export function Lit_get_html() {
    return LitHelpers_html;
}

/**
 * Interprets a template literal as an SVG template that can efficiently render to and update a container.
 * svg is required for nested templates within an svg element
 */
export function Lit_get_svg() {
    return LitHelpers_svg;
}

/**
 * CSS used in the Shadow DOM of LitElements
 */
export function Lit_get_css() {
    return LitHelpers_css;
}

export function Lit_get_nothing() {
    return nothing;
}

/**
 * Renders a Lit TemplateResult to the container.
 */
export function Lit_render(el, t) {
    render(t, el);
}

/**
 * Generates a single string that filters out false-y values from a tuple sequence.
 */
export function Lit_classes_5A743451(classes) {
    return join(" ", choose((tupledArg) => {
        if (tupledArg[1]) {
            return tupledArg[0];
        }
        else {
            return undefined;
        }
    }, classes));
}

/**
 * Generates a string from the string sequence provided
 */
export function Lit_classes_5D66A394(classes) {
    return join(" ", classes);
}

/**
 * Generates inline styles in an efficient way for the browser to apply
 */
export function Lit_styles_4E60E31B(styles) {
    return styleMap(styles);
}

/**
 * Generates inline styles in an efficient way for the browser to apply
 */
export function Lit_styles_68BDC580(styles) {
    return Lit_styles_4E60E31B(createObj(styles));
}

/**
 * Generates inline styles in an efficient way for the browser to apply
 */
export function Lit_styles_Z3AD14642(styles) {
    return styleMap(toSeq(styles));
}

/**
 * Give a unique id to items in a list. This can improve performance in lists that will be sorted, filtered or re-ordered.
 */
export function Lit_mapUnique(getId, template, items) {
    return repeat(items, getId, (x, _arg) => template(x));
}

/**
 * Give a unique id to items in a list and retrieve the current index . This can improve performance in lists that will be sorted, filtered or re-ordered.
 */
export function Lit_mapiUnique(getId, template, items) {
    return repeat(items, getId, (x, i) => template(i, x));
}

/**
 * Shows the placeholder until the promise is resolved
 */
export function Lit_ofPromise_Z87929F5(template, placeholder) {
    return until(template, defaultArg(placeholder, Lit_get_nothing()));
}

/**
 * Only re-render the template if one of the dependencies changes.
 */
export function Lit_onChange_3816F95(dependency, template) {
    return guard(Array.isArray(dependency) ? dependency : [dependency], () => template(dependency));
}

/**
 * Only re-render the template if one of the dependencies changes.
 */
export function Lit_onChange_Z15DFCF7D(dependency1, dependency2, template) {
    return guard([dependency1, dependency2], () => template(dependency1, dependency2));
}

/**
 * Only re-render the template if one of the dependencies changes.
 */
export function Lit_onChange_749DB675(dependency1, dependency2, dependency3, template) {
    return guard([dependency1, dependency2, dependency3], () => template(dependency1, dependency2, dependency3));
}

/**
 * Only re-render the template if one of the dependencies changes.
 */
export function Lit_onChange_Z4F81DE9D(dependency1, dependency2, dependency3, dependency4, template) {
    return guard([dependency1, dependency2, dependency3, dependency4], () => template(dependency1, dependency2, dependency3, dependency4));
}

/**
 * Sets the attribute if the value is Some and removes the attribute if the value is None.
 */
export function Lit_ifSome_6DFDD678(attributeValue) {
    return ifDefined(attributeValue);
}

/**
 * When placed on an element in the template, the ref directive will retrieve a reference to that element once rendered.
 */
export function Lit_refValue_Z4DF72B44(r) {
    return ref(new (class {
        get value() {
            return r.contents;
        }
        set value(v) {
            r.contents = v;
        }
    }
    )());
}

/**
 * When placed on an element in the template, the callback will be called each time the referenced element changes.
 * If a ref callback is rendered to a different element position or is removed in a subsequent render,
 * it will first be called with undefined, followed by another call with the new element it was rendered to (if any).
 */
export function Lit_refCallback_76FF7CD1(fn) {
    return ref(fn);
}

/**
 * Casts the event target to HTMLInputElement and gets the `value` property.
 */
export function Browser_Types_EventTarget__EventTarget_get_Value(this$) {
    return this$.value;
}

/**
 * Casts the event target to HTMLInputElement and gets the `checked` property.
 */
export function Browser_Types_EventTarget__EventTarget_get_Checked(this$) {
    return this$.checked;
}

