import { class_type } from "../fable-library-js.4.19.3/Reflection.js";
import { singleton, concat, map, empty, iterate } from "../fable-library-js.4.19.3/List.js";
import { singleton as singleton_1 } from "../fable-library-js.4.19.3/AsyncBuilder.js";
import { startImmediate, catchAsync } from "../fable-library-js.4.19.3/Async.js";
import { curry2 } from "../fable-library-js.4.19.3/Util.js";

export class Helpers_CmdHandler$2 {
    constructor(handler, dispose) {
        this.handler = handler;
        this.dispose = dispose;
    }
    Dispose() {
        const this$ = this;
        Helpers_CmdHandler$2__Dispose(this$);
    }
}

export function Helpers_CmdHandler$2_$reflection(gen0, gen1) {
    return class_type("ElmishStore.Helpers.CmdHandler`2", [gen0, gen1], Helpers_CmdHandler$2);
}

export function Helpers_CmdHandler$2_$ctor_Z93D263D(handler, dispose) {
    return new Helpers_CmdHandler$2(handler, dispose);
}

export function Helpers_CmdHandler$2__Handle_321F9C1C(_, cmd) {
    _.handler(cmd);
}

export function Helpers_CmdHandler$2__Dispose(_) {
    const matchValue = _.dispose;
    if (matchValue == null) {
    }
    else {
        matchValue();
    }
}

export function Helpers_cmdHandler(update, dispatch) {
    return Helpers_CmdHandler$2_$ctor_Z93D263D((list) => {
        iterate((cmd) => {
            setTimeout(() => {
                cmd(update)(dispatch);
            }, 0);
        }, list);
    });
}

/**
 * None - no commands, also known as `[]`
 */
export function Cmd_none() {
    return empty();
}

/**
 * When emitting the message, map to another type
 */
export function Cmd_map(f, cmd) {
    return map((g) => ((update) => ((dispatch) => {
        g(update)((arg) => {
            dispatch(f(arg));
        });
    })), cmd);
}

/**
 * Aggregate multiple commands
 */
export function Cmd_batch(cmds) {
    return concat(cmds);
}

/**
 * Command to evaluate a simple function and map the result
 * into success or error (of exception)
 */
export function Cmd_OfFunc_either(task, arg, ofSuccess, ofError) {
    return singleton((_update) => ((dispatch) => {
        try {
            dispatch(ofSuccess(task(arg)));
        }
        catch (x) {
            dispatch(ofError(x));
        }
    }));
}

/**
 * Command to evaluate a simple function and map the success to a message
 * discarding any possible error
 */
export function Cmd_OfFunc_perform(task, arg, ofSuccess) {
    return singleton((_update) => ((dispatch) => {
        try {
            dispatch(ofSuccess(task(arg)));
        }
        catch (x) {
        }
    }));
}

/**
 * Command to evaluate a simple function and map the error (in case of exception)
 */
export function Cmd_OfFunc_attempt(task, arg, ofError) {
    return singleton((_update) => ((dispatch) => {
        try {
            task(arg);
        }
        catch (x) {
            dispatch(ofError(x));
        }
    }));
}

/**
 * Command to issue a specific message
 */
export function Cmd_OfFunc_result(msg) {
    return singleton((_update) => ((dispatch) => {
        dispatch(msg);
    }));
}

/**
 * Command that will evaluate an async block and update the model on succes
 * or report a message on error
 */
export function Cmd_OfAsyncWith_update(start, task, arg, onSuccess, onError) {
    return singleton((u) => ((d) => {
        start(singleton_1.Delay(() => singleton_1.Bind(catchAsync(task(arg)), (_arg) => {
            const r = _arg;
            if (r.tag === 1) {
                d(onError(r.fields[0]));
                return singleton_1.Zero();
            }
            else {
                u(curry2(onSuccess)(r.fields[0]));
                return singleton_1.Zero();
            }
        })));
    }));
}

/**
 * Command that will evaluate an async block and map the result
 * into success or error (of exception)
 */
export function Cmd_OfAsyncWith_either(start, task, arg, ofSuccess, ofError) {
    return singleton((u) => ((d) => {
        start(singleton_1.Delay(() => singleton_1.Bind(catchAsync(task(arg)), (_arg) => {
            const r = _arg;
            d((r.tag === 1) ? ofError(r.fields[0]) : ofSuccess(r.fields[0]));
            return singleton_1.Zero();
        })));
    }));
}

/**
 * Command that will evaluate an async block and map the success
 */
export function Cmd_OfAsyncWith_perform(start, task, arg, ofSuccess) {
    return singleton((u) => ((d) => {
        start(singleton_1.Delay(() => singleton_1.Bind(catchAsync(task(arg)), (_arg) => {
            const r = _arg;
            if (r.tag === 0) {
                d(ofSuccess(r.fields[0]));
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Zero();
            }
        })));
    }));
}

/**
 * Command that will evaluate an async block and map the error (of exception)
 */
export function Cmd_OfAsyncWith_attempt(start, task, arg, ofError) {
    return singleton((u) => ((d) => {
        start(singleton_1.Delay(() => singleton_1.Bind(catchAsync(task(arg)), (_arg) => {
            const r = _arg;
            if (r.tag === 1) {
                d(ofError(r.fields[0]));
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Zero();
            }
        })));
    }));
}

/**
 * Command that will evaluate an async block to the message
 */
export function Cmd_OfAsyncWith_result(start, task) {
    return singleton((u) => ((d) => {
        start(singleton_1.Delay(() => singleton_1.Bind(task, (_arg) => {
            d(_arg);
            return singleton_1.Zero();
        })));
    }));
}

export function Cmd_OfAsync_start(x) {
    startImmediate(x);
}

