import { class_type } from "../fable-library-js.4.19.3/Reflection.js";
import { iterate } from "../fable-library-js.4.19.3/Seq.js";
import { addToDict } from "../fable-library-js.4.19.3/MapUtil.js";

export class Store$1 {
    constructor(initValue, dispose) {
        this.dispose = dispose;
        this._uid = 0;
        this._disposed = false;
        this._value = initValue;
        this.subscribers = (new Map([]));
    }
    Dispose() {
        const this$ = this;
        Store$1__Dispose(this$);
    }
    Update(f) {
        const this$ = this;
        Store$1__Update_Z1FC644CA(this$, f);
    }
    Subscribe(observer) {
        const this$ = this;
        return Store$1__Subscribe_32482748(this$, observer);
    }
}

export function Store$1_$reflection(gen0) {
    return class_type("Fable.Store`1", [gen0], Store$1);
}

export function Store$1_$ctor_Z35EE7837(initValue, dispose) {
    return new Store$1(initValue, dispose);
}

export function Store$1__Dispose(_) {
    if (!_._disposed) {
        _.subscribers.clear();
        const matchValue = _.dispose;
        if (matchValue == null) {
        }
        else {
            matchValue(_._value);
        }
    }
}

export function Store$1__Update_Z1FC644CA(_, f) {
    _._value = f(_._value);
    iterate((s) => {
        s.OnNext(_._value);
    }, _.subscribers.values());
}

export function Store$1__Subscribe_32482748(this$, observer) {
    if (this$._disposed) {
        throw new Error("The store has already been disposed");
    }
    else {
        observer.OnNext(this$._value);
        const id = this$._uid | 0;
        this$._uid = ((this$._uid + 1) | 0);
        addToDict(this$.subscribers, id, observer);
        return {
            Dispose() {
                if (this$.subscribers.delete(id) && (this$.subscribers.size === 0)) {
                    Store$1__Dispose(this$);
                }
            },
        };
    }
}

