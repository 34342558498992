import { ProgramModule_run, ProgramModule_view, ProgramModule_withSetState, ProgramModule_mkProgram } from "../Fable.Elmish.4.2.0/program.fs.js";
import { Lit_render } from "../Fable.Lit.1.4.2/Lit.fs.js";
import { class_type } from "../fable-library-js.4.19.3/Reflection.js";
import { value, some } from "../fable-library-js.4.19.3/Option.js";
import { Hook_createDisposable_3A5B6456, Lit_HookContext__HookContext_useMemo_FCFD9EF } from "../Fable.Lit.1.4.2/Hook.fs.js";
import { disposeSafe, isDisposable } from "../fable-library-js.4.19.3/Util.js";

/**
 * Creates an elmish program without a view function.
 * Useful for testing or using the program with `Hook.useElmish`.
 */
export function Program_mkHidden(init, update) {
    return ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    });
}

/**
 * Mounts an Elmish loop in the specified element
 */
export function Program_withLitOnElement(el, program) {
    return ProgramModule_withSetState((model, dispatch) => {
        Lit_render(el, ProgramModule_view(program)(model)(dispatch));
    }, program);
}

/**
 * Mounts an Elmish loop in the element with the specified id
 */
export function Program_withLit(id, program) {
    const el = document.getElementById(id);
    if (el == null) {
        throw new Error(`Cannot find element with id ${id}`);
    }
    return Program_withLitOnElement(el, program);
}

export class LitElmishExtensions_ElmishObservable$2 {
    constructor() {
        this.state = undefined;
        this.listener = undefined;
        this.dispatcher = undefined;
    }
}

export function LitElmishExtensions_ElmishObservable$2_$reflection(gen0, gen1) {
    return class_type("Lit.Elmish.LitElmishExtensions.ElmishObservable`2", [gen0, gen1], LitElmishExtensions_ElmishObservable$2);
}

export function LitElmishExtensions_ElmishObservable$2_$ctor() {
    return new LitElmishExtensions_ElmishObservable$2();
}

export function LitElmishExtensions_ElmishObservable$2__get_Value(_) {
    return _.state;
}

export function LitElmishExtensions_ElmishObservable$2__SetState(_, model, dispatch) {
    _.state = some(model);
    _.dispatcher = dispatch;
    const matchValue = _.listener;
    if (matchValue != null) {
        matchValue(model);
    }
}

export function LitElmishExtensions_ElmishObservable$2__Dispatch_2B594(_, msg) {
    const matchValue = _.dispatcher;
    if (matchValue != null) {
        matchValue(msg);
    }
}

export function LitElmishExtensions_ElmishObservable$2__Subscribe_5028453F(_, f) {
    const matchValue = _.listener;
    if (matchValue == null) {
        _.listener = f;
    }
}

export function LitElmishExtensions_useElmish(ctx, program) {
    const obs = Lit_HookContext__HookContext_useMemo_FCFD9EF(ctx, LitElmishExtensions_ElmishObservable$2_$ctor);
    const patternInput = ctx.useState(() => {
        ProgramModule_run(ProgramModule_withSetState((model, dispatch) => {
            LitElmishExtensions_ElmishObservable$2__SetState(obs, model, dispatch);
        }, program()));
        const matchValue = LitElmishExtensions_ElmishObservable$2__get_Value(obs);
        if (matchValue != null) {
            return value(matchValue);
        }
        else {
            throw new Error("Elmish program has not initialized");
        }
    });
    const state = patternInput[0];
    ctx.useEffectOnce(() => Hook_createDisposable_3A5B6456(() => {
        const matchValue_1 = state;
        if (isDisposable(matchValue_1)) {
            const disp = matchValue_1;
            disposeSafe(disp);
        }
    }));
    LitElmishExtensions_ElmishObservable$2__Subscribe_5028453F(obs, patternInput[1]);
    return [state, (msg) => {
        LitElmishExtensions_ElmishObservable$2__Dispatch_2B594(obs, msg);
    }];
}

